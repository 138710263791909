/* Autogenerated file. Do not edit manually. */

/* tslint:disable */
/* eslint-disable */

/*
  Fuels version: 0.89.1
  Forc version: 0.60.0
  Fuel-Core version: 0.27.0
*/

import { Interface, Contract, ContractFactory } from "fuels";
import type { Provider, Account, AbstractAddress, BytesLike, DeployContractOptions, StorageSlot } from "fuels";
import type { ThunderExchangeAbi, ThunderExchangeAbiInterface } from "../ThunderExchangeAbi";

const _abi = {
  "encoding": "1",
  "types": [
    {
      "typeId": 0,
      "type": "()",
      "components": [],
      "typeParameters": null
    },
    {
      "typeId": 1,
      "type": "b256",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 2,
      "type": "enum AccessError",
      "components": [
        {
          "name": "CannotReinitialized",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "NotOwner",
          "type": 0,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 3,
      "type": "enum Identity",
      "components": [
        {
          "name": "Address",
          "type": 8,
          "typeArguments": null
        },
        {
          "name": "ContractId",
          "type": 10,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 4,
      "type": "enum Option",
      "components": [
        {
          "name": "None",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "Some",
          "type": 7,
          "typeArguments": null
        }
      ],
      "typeParameters": [
        7
      ]
    },
    {
      "typeId": 5,
      "type": "enum Side",
      "components": [
        {
          "name": "Buy",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "Sell",
          "type": 0,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 6,
      "type": "enum ThunderExchangeErrors",
      "components": [
        {
          "name": "OnlyOwner",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "Initialized",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AmountHigherThanPoolBalance",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AssetIdNotMatched",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AmountNotMatched",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "StrategyMustBeNonZeroContract",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "StrategyNotWhitelisted",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "MakerMustBeNonZeroAddress",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "TakerMustBeNonZeroAddress",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "CallerMustBeMaker",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "ExpirationRangeOutOfBound",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "NonceMustBeNonZero",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "PriceMustBeNonZero",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AmountMustBeNonZero",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AssetNotSupported",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "ExecutionInvalid",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "PaymentAssetMismatched",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "PriceMismatched",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AmountMismatched",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "PoolTransferFromFailed",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "PoolMismatchedAssetBalance",
          "type": 0,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 7,
      "type": "generic T",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 8,
      "type": "struct Address",
      "components": [
        {
          "name": "bits",
          "type": 1,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 9,
      "type": "struct AssetId",
      "components": [
        {
          "name": "bits",
          "type": 1,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 10,
      "type": "struct ContractId",
      "components": [
        {
          "name": "bits",
          "type": 1,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 11,
      "type": "struct ExtraParams",
      "components": [
        {
          "name": "extra_address_param",
          "type": 8,
          "typeArguments": null
        },
        {
          "name": "extra_contract_param",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "extra_u64_param",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 12,
      "type": "struct MakerOrder",
      "components": [
        {
          "name": "side",
          "type": 5,
          "typeArguments": null
        },
        {
          "name": "maker",
          "type": 8,
          "typeArguments": null
        },
        {
          "name": "collection",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 1,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "amount",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "nonce",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "strategy",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "payment_asset",
          "type": 9,
          "typeArguments": null
        },
        {
          "name": "start_time",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "end_time",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "extra_params",
          "type": 11,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 13,
      "type": "struct MakerOrderInput",
      "components": [
        {
          "name": "side",
          "type": 5,
          "typeArguments": null
        },
        {
          "name": "maker",
          "type": 8,
          "typeArguments": null
        },
        {
          "name": "collection",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 1,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "amount",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "nonce",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "strategy",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "payment_asset",
          "type": 9,
          "typeArguments": null
        },
        {
          "name": "expiration_range",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "extra_params",
          "type": 11,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 14,
      "type": "struct OrderCanceled",
      "components": [
        {
          "name": "user",
          "type": 8,
          "typeArguments": null
        },
        {
          "name": "strategy",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "side",
          "type": 5,
          "typeArguments": null
        },
        {
          "name": "nonce",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 15,
      "type": "struct OrderExecuted",
      "components": [
        {
          "name": "order",
          "type": 21,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 16,
      "type": "struct OrderPlaced",
      "components": [
        {
          "name": "order",
          "type": 12,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 17,
      "type": "struct OrderUpdated",
      "components": [
        {
          "name": "order",
          "type": 12,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 18,
      "type": "struct OwnershipRenounced",
      "components": [
        {
          "name": "previous_owner",
          "type": 3,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 19,
      "type": "struct OwnershipSet",
      "components": [
        {
          "name": "new_owner",
          "type": 3,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 20,
      "type": "struct OwnershipTransferred",
      "components": [
        {
          "name": "new_owner",
          "type": 3,
          "typeArguments": null
        },
        {
          "name": "previous_owner",
          "type": 3,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 21,
      "type": "struct TakerOrder",
      "components": [
        {
          "name": "side",
          "type": 5,
          "typeArguments": null
        },
        {
          "name": "taker",
          "type": 8,
          "typeArguments": null
        },
        {
          "name": "maker",
          "type": 8,
          "typeArguments": null
        },
        {
          "name": "nonce",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 1,
          "typeArguments": null
        },
        {
          "name": "collection",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "strategy",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "extra_params",
          "type": 11,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 22,
      "type": "u64",
      "components": null,
      "typeParameters": null
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "strategy",
          "type": 10,
          "typeArguments": null
        },
        {
          "name": "nonce",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "side",
          "type": 5,
          "typeArguments": null
        }
      ],
      "name": "cancel_order",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "order",
          "type": 21,
          "typeArguments": null
        }
      ],
      "name": "execute_order",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_asset_manager",
      "output": {
        "name": "",
        "type": 10,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_execution_manager",
      "output": {
        "name": "",
        "type": 10,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_max_expiration",
      "output": {
        "name": "",
        "type": 22,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_min_expiration",
      "output": {
        "name": "",
        "type": 22,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_pool",
      "output": {
        "name": "",
        "type": 10,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Getters ///"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_protocol_fee_recipient",
      "output": {
        "name": "",
        "type": 3,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_royalty_manager",
      "output": {
        "name": "",
        "type": 10,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_transfer_selector",
      "output": {
        "name": "",
        "type": 10,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "initialize",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "owner",
      "output": {
        "name": "",
        "type": 4,
        "typeArguments": [
          {
            "name": "",
            "type": 3,
            "typeArguments": null
          }
        ]
      },
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Ownable ///"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "order_input",
          "type": 13,
          "typeArguments": null
        }
      ],
      "name": "place_order",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [],
      "name": "renounce_ownership",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "asset_manager",
          "type": 10,
          "typeArguments": null
        }
      ],
      "name": "set_asset_manager",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "execution_manager",
          "type": 10,
          "typeArguments": null
        }
      ],
      "name": "set_execution_manager",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "new_max_expiration",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "set_max_expiration",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "new_min_expiration",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "set_min_expiration",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "pool",
          "type": 10,
          "typeArguments": null
        }
      ],
      "name": "set_pool",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " Setters ///"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "protocol_fee_recipient",
          "type": 3,
          "typeArguments": null
        }
      ],
      "name": "set_protocol_fee_recipient",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "royalty_manager",
          "type": 10,
          "typeArguments": null
        }
      ],
      "name": "set_royalty_manager",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "transfer_selector",
          "type": 10,
          "typeArguments": null
        }
      ],
      "name": "set_transfer_selector",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "new_owner",
          "type": 3,
          "typeArguments": null
        }
      ],
      "name": "transfer_ownership",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "order_input",
          "type": 13,
          "typeArguments": null
        }
      ],
      "name": "update_order",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "5160872022870533508",
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": "13612721533416287670",
      "loggedType": {
        "name": "",
        "type": 14,
        "typeArguments": []
      }
    },
    {
      "logId": "16905867214671608396",
      "loggedType": {
        "name": "",
        "type": 15,
        "typeArguments": []
      }
    },
    {
      "logId": "12764806370357731851",
      "loggedType": {
        "name": "",
        "type": 2,
        "typeArguments": []
      }
    },
    {
      "logId": "9517900813706399297",
      "loggedType": {
        "name": "",
        "type": 19,
        "typeArguments": []
      }
    },
    {
      "logId": "13895587280595317858",
      "loggedType": {
        "name": "",
        "type": 16,
        "typeArguments": []
      }
    },
    {
      "logId": "7912903559520169914",
      "loggedType": {
        "name": "",
        "type": 18,
        "typeArguments": []
      }
    },
    {
      "logId": "10699517786846983752",
      "loggedType": {
        "name": "",
        "type": 20,
        "typeArguments": []
      }
    },
    {
      "logId": "5118125025934262562",
      "loggedType": {
        "name": "",
        "type": 17,
        "typeArguments": []
      }
    }
  ],
  "messagesTypes": [],
  "configurables": []
};

const _storageSlots: StorageSlot[] = [
  {
    "key": "02dac99c283f16bc91b74f6942db7f012699a2ad51272b15207b9cc14a70dbae",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "02dac99c283f16bc91b74f6942db7f012699a2ad51272b15207b9cc14a70dbaf",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "6294951dcb0a9111a517be5cf4785670ff4e166fb5ab9c33b17e6881b48e964f",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "6294951dcb0a9111a517be5cf4785670ff4e166fb5ab9c33b17e6881b48e9650",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "7f91d1a929dce734e7f930bbb279ccfccdb5474227502ea8845815c74bd930a7",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "7f91d1a929dce734e7f930bbb279ccfccdb5474227502ea8845815c74bd930a8",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "8a89a0cce819e0426e565819a9a98711329087da5a802fb16edd223c47fa44ef",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "8a89a0cce819e0426e565819a9a98711329087da5a802fb16edd223c47fa44f0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "94b2b70d20da552763c7614981b2a4d984380d7ed4e54c01b28c914e79e44bd5",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "94b2b70d20da552763c7614981b2a4d984380d7ed4e54c01b28c914e79e44bd6",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "a9203bbb8366ca9d708705dce980acbb54d44fb753370ffe4c7d351b46b2abbc",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "b48b753af346966d0d169c0b2e3234611f65d5cfdb57c7b6e7cd6ca93707bee0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "b48b753af346966d0d169c0b2e3234611f65d5cfdb57c7b6e7cd6ca93707bee1",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "c5e69153be998bc6f957aeb6f8fd46a0e9c5bc2d3dff421a73e02f64a3012fbb",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "de9090cb50e71c2588c773487d1da7066d0c719849a7e58dc8b6397a25c567c0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "de9090cb50e71c2588c773487d1da7066d0c719849a7e58dc8b6397a25c567c1",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "f383b0ce51358be57daa3b725fe44acdb2d880604e367199080b4379c41bb6ed",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export const ThunderExchangeAbi__factory = {
  abi: _abi,

  storageSlots: _storageSlots,

  createInterface(): ThunderExchangeAbiInterface {
    return new Interface(_abi) as unknown as ThunderExchangeAbiInterface
  },

  connect(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider
  ): ThunderExchangeAbi {
    return new Contract(id, _abi, accountOrProvider) as unknown as ThunderExchangeAbi
  },

  async deployContract(
    bytecode: BytesLike,
    wallet: Account,
    options: DeployContractOptions = {}
  ): Promise<ThunderExchangeAbi> {
    const factory = new ContractFactory(bytecode, _abi, wallet);

    const contract = await factory.deployContract({
      storageSlots: _storageSlots,
      ...options,
    });

    return contract as unknown as ThunderExchangeAbi;
  }
}
